import React, { useState, useEffect, useRef } from 'react'
// import "../../rels_assets/js/mmenu.js";
// import "../../rels_assets/js/timedropper.js"
import image from "../../rels_assets/images/single-property/s-1.jpg";
import nopropertyfoundimg from "../../rels_assets/images/property/no-propertyfound.png";
import noamenitylogo from "../../rels_assets/images/property/placeholder-250x250.png";
import Slider from "react-slick";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useParams } from 'react-router-dom';
import request from '../../service/request';
import PageNotFound from '../common/pagenotfound';
import Skeleton from 'react-loading-skeleton';
import PropertyListBlock from '../common/Property/propertyListBlock';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHotPropertyRequest } from '../Landing/actions';
import $, { data } from 'jquery';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { addSysMessage } from '../notifications/system/sysMessageAction';
import { SYS_MESSAGE_TYPE_SUCCESS } from '../notifications/system/components/Message';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import MetaTags from 'react-meta-tags';

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};
const MailPropertyDetails = (props) => {

    const dispatch = useDispatch();
    let { uid } = useParams();

    const phoneRegExp = /^[6789]\d{9}$/;
    const validation = yup.object().shape({
        full_name: yup.string().min(2).max(30, "maximum 30 character allowed").required("Full Name is required."),
        email_address: yup.string().email().required("Email is required."),
        phone_number: yup.string().matches(phoneRegExp, 'Phone number is not valid').required("Phone number is required."),
        message: yup.string().required("Message is required.").min(10).max(500, "Message can be maximum of 500 characters only."),
    });

    const initialValues = {
        property_uid: uid,
        full_name: '',
        phone_number: '',
        email_address: '',
        message: '',
    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, isValid } = useFormik({
        initialValues: initialValues,
        validationSchema: validation,
        onSubmit: (values, { resetForm }) => {
            inquiryRequest(values);
            resetForm();
        }
    })

    const [property, setProperty] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [propertyNotExist, setPropertyNotExist] = useState(false);
    const [currency, setCurrency] = useState([]);
    const [similarProperties, setSimilarProperties] = useState([]);
    const [recentProperties, setRecentProperties] = useState([]);
    const [menu, setMenu] = useState([]);
    const hotproperties = useSelector(store => store.landingPageData.hotPropertyData.length > 0 ? store.landingPageData.hotPropertyData : store.landingPageData.hotPropertyData);



    var carouselSetting = {
        autoPlay: false,
        interval: 5000,
        infiniteLoop: true,
        transitionTime: 1000,
        showStatus: false,
        showArrows: true,
        showThumbs: true,
        thumbWidth: 127,
        // renderPrevButton:'<a className="carousel-control left" href="#listingDetailsSlider" data-slide="prev"><i className="fa fa-angle-left"></i></a>',
        // renderNextButton:'<a className="carousel-control right" href="#listingDetailsSlider" data-slide="next"><i className="fa fa-angle-right"></i></a>',
    }

    var featuredSlider = {
        dots: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2500,
        pauseOnHover: true,
        // nextArrow: false,
        // prevArrow: false
    };

    const vidRef = useRef(null);
    const handlePlayVideo = () => {
        vidRef.current.play();
    }

    function playVideo() {
        this.refs.vidRef.play();
    }

    useEffect(() => {
        scrollToTop();
        loadData();
    }, []);
    useEffect(() => { scrollToTop(); loadData(); }, [props]);
    function loadData() {
        request({
            method: 'post',
            url: '/property/mail/get-detail-by-id',
            headers: { "Content-Type": "application/json", "Accept": "application/json" },
            data: { 'property_uid': uid }
        })
            .then((response) => {
                if (Object.keys(response.data.data).length > 0) {
                    setProperty(response.data.data);
                    setSimilarProperties(response.data.similar_properties);
                    setRecentProperties(response.data.recent_properties);
                    setCurrency(response.data.currency);
                    setIsLoading(false)
                } else {
                    setPropertyNotExist(true);
                }
            })
            .catch(({ data }) => {
                setPropertyNotExist(true);
            });

        /** Hot properties */
        if (hotproperties.length == 0) {
            dispatch(fetchHotPropertyRequest(JSON.stringify([data])));
        }
    }


    useEffect(() => {
        scrollToTop();
        request({
            method: 'get',
            url: '/get-menu',
            headers: { "Content-Type": "application/json", "Accept": "application/json" },
        })
            .then((response) => {
                setMenu(response?.data);
            })
            .catch(({ data }) => ({ error: data }));
    }, []);

    function inquiryRequest(formValues) {
        request({
            method: 'post',
            url: '/inquiry/save',
            headers: { "Content-Type": "application/json", "Accept": "application/json" },
            data: formValues
        })
            .then((response) => {
                dispatch(addSysMessage(response?.data?.message, SYS_MESSAGE_TYPE_SUCCESS));
            })
            .catch(({ data }) => ({ error: data }));
    }

    return (
        <>
            {
                propertyNotExist && Object.keys(property).length < 1 ? <PageNotFound /> :
                    <>
                        {property && Object.keys(property).length > 0 && isLoading == false ?
                            <>
                                <div>
                                    {/* <HelmetProvider>
                                        <Helmet>
                                            
                                            <title>{(property && Object.keys(property).length > 0) ? `${menu.data?.site_setting?.site_name} - ${property?.property_title}` : property?.property_title}</title>
                                            <meta name="title" content={property && property && Object.keys(property).length > 0 && property?.property_title} />
                                            <meta name="description" content={property && property && Object.keys(property).length > 0 && property?.property_title} />
                                            {
                                                property &&
                                                <script type="application/ld+json">
                                                    {JSON.stringify({
                                                        "@context": "https://schema.org/",
                                                        "@type": "Website",
                                                        "name": property?.meta_title,
                                                        "description": property?.meta_description,
                                                    })}
                                                </script>
                                            }
                                        </Helmet>
                                    </HelmetProvider> */}
                                    <MetaTags>
                                        <title>{(property && Object.keys(property).length > 0) ? `${menu.data?.site_setting?.site_name} - ${property?.property_title}` : property?.property_title}</title>
                                        <meta name="og:title" content={property && property && Object.keys(property).length > 0 && property?.property_title} />
                                        <meta name="description" content={property && property && Object.keys(property).length > 0 && property?.property_title} />
                                        <meta property="og:image" content={nopropertyfoundimg} />

                                        <meta name="twitter:card" content="summary_large_image" />
                                        <meta property="twitter:domain" content="realestate.techcelerity.com" />
                                        <meta property="twitter:url" content={window.location.href} />
                                        <meta name="twitter:title" content={property && property && Object.keys(property).length > 0 && property?.property_title} />
                                        <meta name="twitter:description" content={property && property && Object.keys(property).length > 0 && property?.property_title} />
                                        <meta name="twitter:image" content="https://realestate.techcelerity.com/storage/uploads/property_images/1710856864_398146375_6699219140162378_7936910362399474739_n.jpg" />
                                    </MetaTags>
                                </div>
                                {/* <!-- START SECTION PROPERTIES LISTING --> */}
                                <section className="single-proper blog details">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-8 col-md-12 blog-pots">
                                                <div className="row">
                                                    <div className='col-12 widget-boxed-header py-0 my-0 border-0'>
                                                        <h3 className='h3 my-0'>{property?.property_title}</h3>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <section className="headings-2 pt-0">
                                                            <div className="pro-wrapper align-items-start align-items-md-end">
                                                                <div className="detail-wrapper-body">
                                                                    <div className="listing-title-bar">
                                                                        <div className="mb-3">
                                                                            <a className="listing-address">
                                                                                <i className="fa fa-map-marker pr-2 ti-location-pin mrg-r-5"></i>
                                                                                {property?.location}
                                                                            </a>
                                                                        </div>
                                                                        <span className="mrg-l-5 category-tag">{property?.property_for?.name}</span>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    property && property?.price && property?.show_price_in_website == 1 ?
                                                                        <div className="single detail-wrapper mr-2">
                                                                            <div className="detail-wrapper-body">
                                                                                <div className="listing-title-bar">
                                                                                    <h4 className='listing-address'>{property?.price}</h4>
                                                                                    <div className="py-2">
                                                                                        <a className="listing-address">
                                                                                            <p className='listing-address mb-0'>{property.per_price}</p>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div> : ''
                                                                }

                                                            </div>
                                                        </section>
                                                        {/* <!-- main slider carousel items --> */}
                                                        {
                                                            <div id="listingDetailsSlider" className="similar-property listing-details-sliders slide mb-30">
                                                                <h5 className="mb-4 text-dark font-weight-bold">Gallery</h5>
                                                                <div className="carousel-inner">

                                                                    <Carousel {...carouselSetting}

                                                                        renderThumbs={() =>
                                                                            property.property_images.length > 0 && property.property_images.map((thumbImage, index) => {
                                                                                let filename = thumbImage.image.split('/').pop();
                                                                                let extension = filename.split('.').pop();
                                                                                if (extension && (['m4v', 'mp4', 'mov', 'wmv', 'avi', 'mkv'].includes(extension))) {
                                                                                    return (
                                                                                        <div className="active item" data-slide-number={index}>
                                                                                            <div className='img-thumbnail'>
                                                                                                <video alt="slider-listing" className='video-thumb'>
                                                                                                    <source src={thumbImage.image} type="video/mp4" />
                                                                                                </video>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <>
                                                                                            <div className="active item" data-slide-number={index}>
                                                                                                <img loading='lazy' src={thumbImage.image} className="img-fluid" alt="slider-listing" onError={(e) => (
                                                                                                    e.target.onerror = null,
                                                                                                    e.target.src = nopropertyfoundimg
                                                                                                )
                                                                                                } />
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                }

                                                                            })
                                                                        }

                                                                        renderArrowPrev={(clickHandler, hasPrev) => {
                                                                            return (
                                                                                <a className="carousel-control left" data-slide="prev" onClick={clickHandler}><i className="fa fa-angle-left text-white"></i></a>
                                                                            );
                                                                        }}
                                                                        renderArrowNext={(clickHandler, hasNext) => {
                                                                            return (
                                                                                <a className="carousel-control right" data-slide="next" onClick={clickHandler}><i className="fa fa-angle-right text-white"></i></a>
                                                                            );
                                                                        }}
                                                                    >
                                                                        {(property && property.property_images.length > 0) ? property.property_images.map((item, index) => {
                                                                            let filename = item.image.split('/').pop();
                                                                            let extension = filename.split('.').pop();
                                                                            if (extension && (['m4v', 'mp4', 'mov', 'wmv', 'avi', 'mkv'].includes(extension))) {
                                                                                return (
                                                                                    <div className='wprt-image-video'>
                                                                                        {/* <button onClick={playVideo.bind(this)} className="icon-wrap popup-video popup-youtube" href={item.image}  >
                                                                                            <i className="fa fa-play"></i>
                                                                                        </button> */}
                                                                                        <video autoPlay muted controls className="img-fluid" alt="slider-listing" name="video-name">
                                                                                            <source src={item.image} type="video/mp4" />
                                                                                        </video>
                                                                                        {/* <div className="iq-waves">
                                                                                            <div className="waves wave-1"></div>
                                                                                            <div className="waves wave-2"></div>
                                                                                            <div className="waves wave-3"></div>
                                                                                        </div> */}
                                                                                    </div>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <div className="active item" data-slide-number={index}>
                                                                                        <img loading='lazy' src={item.image} className="img-fluid" alt="slider-listing" onError={(e) => (
                                                                                            e.target.onerror = null,
                                                                                            e.target.src = nopropertyfoundimg
                                                                                        )
                                                                                        } />
                                                                                    </div>
                                                                                )
                                                                            }

                                                                        }) : <div className="active item" data-slide-number="0">
                                                                            <img loading='lazy' src={nopropertyfoundimg} className="img-fluid" alt="slider-listing" />
                                                                        </div>
                                                                        }
                                                                    </Carousel>
                                                                </div>
                                                            </div>
                                                        }

                                                    </div>
                                                </div>
                                                <div className="single homes-content details mb-30">
                                                    {/* <!-- title --> */}
                                                    <h5 className="mb-4">Property Details</h5>
                                                    {/* <!-- title --> */}
                                                    {
                                                        property && property.property_type_id != 4 ?
                                                            <>
                                                                <ul className="property-dtl homes-list clearfix">
                                                                    <li>
                                                                        <span className="font-weight-bold mr-1">Property ID:</span>
                                                                        <span className="det">{property.unique_id}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="font-weight-bold mr-1">Property Type:</span>
                                                                        <span className="det">{property?.property_type?.name}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="font-weight-bold mr-1">Property For:</span>
                                                                        <span className="det">{property?.property_for?.name}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="font-weight-bold mr-1">Specific Property Type:</span>
                                                                        <span className="det">{property?.specific_property_type?.name}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="font-weight-bold mr-1">Configuration:</span>
                                                                        <span className="det">{(property?.property_config) ? property?.property_config.name : ' - '}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="font-weight-bold mr-1">Area (Locality):</span>
                                                                        <span className="det">{property?.property_area?.area}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="font-weight-bold mr-1">Floor:</span>
                                                                        <span className="det">{property?.floor}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="font-weight-bold mr-1">Super Builtup Area:</span>
                                                                        <span className="det">{property?.built_up_area}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="font-weight-bold mr-1">Carpet Area:</span>
                                                                        <span className="det">{property?.carpet_area}</span>
                                                                    </li>
                                                                    {
                                                                        property && property?.price && property?.show_price_in_website == 1 ?
                                                                            <li>
                                                                                <span className="font-weight-bold mr-1">Total Price:</span>
                                                                                <span className="det">{property?.price}</span>
                                                                            </li> : ''
                                                                    }
                                                                    {
                                                                        property && property?.per_price && property?.show_price_in_website == 1 ?
                                                                            <li>
                                                                                <span className="font-weight-bold mr-1">Price Per Sqft:</span>
                                                                                <span className="det">{property?.per_price}</span>
                                                                            </li> : ''
                                                                    }
                                                                    <li>
                                                                        <span className="font-weight-bold mr-1">Facing:</span>
                                                                        <span className="det">{property?.facing_area}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="font-weight-bold mr-1">Furnished Status:</span>
                                                                        <span className="det">{property?.furnished_status?.name}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="font-weight-bold mr-1">Four Wheeler Parkings:</span>
                                                                        <span className="det">{property?.parking_four_wheel}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="font-weight-bold mr-1">Two Wheeler Parkings:</span>
                                                                        <span className="det">{property?.parking_two_wheel}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="font-weight-bold mr-1">Property status:</span>
                                                                        <span className="det">{property?.property_status?.name}</span>
                                                                    </li>
                                                                </ul>
                                                                <h5 className="mt-5">Building Amenities</h5>
                                                                {/* <!-- cars List --> */}
                                                                {
                                                                    <ul className="homes-list clearfix amenity-img">
                                                                        {property && property.property_building && property.property_building.building_amenities.length > 0 ? property.property_building.building_amenities.map((item) => {
                                                                            return (
                                                                                <li>
                                                                                    <div className="row mx-0 align-items-center">
                                                                                        <div className="col-2 px-0">
                                                                                            {item.amenity?.logo ?
                                                                                                <img loading='lazy' src={item.amenity.logo} align="left" className="img-fluid" alt="property-amenity" onError={(e) => (
                                                                                                    e.target.onerror = null,
                                                                                                    e.target.src = noamenitylogo
                                                                                                )
                                                                                                } style={{ height: '37px', width: '37px' }} />
                                                                                                : <img loading='lazy' style={{ height: '37px' }} />
                                                                                            }
                                                                                        </div>
                                                                                        <div className="col-10 px-2">
                                                                                            <span className='ml-2 align-middle amenity-img-text'>{item.amenity.name}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        }) : <> <li>
                                                                            <span>No Amenity Available</span>
                                                                        </li></>}
                                                                    </ul>
                                                                }
                                                            </> : <>
                                                                <ul className="property-dtl homes-list clearfix">
                                                                    <li>
                                                                        <span className="font-weight-bold mr-1">Property ID:</span>
                                                                        <span className="det">{property.unique_id}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="font-weight-bold mr-1">Property Type:</span>
                                                                        <span className="det">{property?.property_type?.name}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="font-weight-bold mr-1">Property For:</span>
                                                                        <span className="det">{property?.property_for?.name}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="font-weight-bold mr-1">Specific Property Type:</span>
                                                                        <span className="det">{property?.specific_property_type?.name}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="font-weight-bold mr-1">Area (Locality):</span>
                                                                        <span className="det">{property?.location}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="font-weight-bold mr-1">F.P Size Area:</span>
                                                                        <span className="det">{property?.FP_no_size} {property.fp_no_size_measurement?.measurement}</span>
                                                                    </li>
                                                                    {
                                                                        property && property?.price && property?.show_price_in_website == 1 ?
                                                                            <li>
                                                                                <span className="font-weight-bold mr-1">Total Price:</span>
                                                                                <span className="det">{property?.price}</span>
                                                                            </li> : ''
                                                                    }
                                                                    <li>
                                                                        <span className="font-weight-bold mr-1">Facing:</span>
                                                                        <span className="det">{property?.facing_area}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="font-weight-bold mr-1">Property status:</span>
                                                                        <span className="det">{property?.property_status?.name}</span>
                                                                    </li>
                                                                </ul>
                                                            </>
                                                    }

                                                </div>
                                            </div>
                                            <aside className="col-lg-4 col-md-12 car">
                                                <div className="single widget">
                                                    {/* <!-- End: Schedule a Tour --> */}
                                                    {/* <!-- end author-verified-badge --> */}
                                                    <div className="sidebar">
                                                        <div className="widget-boxed mt-33 mt-5">
                                                            <div className="widget-boxed-header">
                                                                <h4>Request Inquiry</h4>
                                                            </div>
                                                            <div className="sidebar-widget author-widget2">
                                                                <div className="agent-contact-form-sidebar">
                                                                    <form name="inquiry_form" method="post" onSubmit={handleSubmit}>
                                                                        <input type="hidden" name="property_id" value={values.property_id} />
                                                                        <input type="text" id={values.id} value={values.full_name} onChange={handleChange} onBlur={handleBlur} name="full_name" placeholder="Enter Full Name" />
                                                                        {errors.full_name && touched.full_name ? (<div className="text-danger fw-bold">{errors.full_name}</div>) : " "}
                                                                        <input type="text" id={values.id} value={values.phone_number} onChange={handleChange} onBlur={handleBlur} name="phone_number" placeholder="Phone No (10 digit without country code)" />
                                                                        {errors.phone_number && touched.phone_number ? (<div className="text-danger ">{errors.phone_number}</div>) : " "}
                                                                        <input type="email" id={values.id} value={values
                                                                            .email_address} onChange={handleChange}
                                                                            onBlur={handleBlur} name="email_address" placeholder="Enter 10-digit Phone Number" />
                                                                        {errors.email_address && touched.email_address ? (<div className="text-danger ">{errors.email_address}</div>) : " "}
                                                                        <textarea id={values.id} value={values.message} onChange={handleChange} onBlur={handleBlur} placeholder="Enter Message" name="message"></textarea>
                                                                        {errors.message && touched.message ? (<div className="text-danger ">{errors.message}</div>) : " "}
                                                                        <input type="submit" disabled={!isValid} name="sendmessage" className="multiple-send-message" value="Submit Request" />
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </aside>
                                        </div>
                                    </div>
                                </section>
                                {/* <!-- END SECTION PROPERTIES LISTING --> */}
                            </> : <>
                                <section className="single-proper blog details">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-8 col-md-12 blog-pots">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <section className='headings-2 pt-0'>
                                                            <div className="pro-wrapper">
                                                                <div className="detail-wrapper-body">
                                                                    <div className="listing-title-bar">
                                                                        <Skeleton width={100} height={30} style={{ borderRadius: "50px" }} />
                                                                        <div className="mt-0">
                                                                            <a href="#listing-location" className="listing-address">
                                                                                <Skeleton width={300} height={20} style={{ borderRadius: "50px" }} /> </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="single detail-wrapper mr-2">
                                                                    <div className="detail-wrapper-body">
                                                                        <div className="listing-title-bar">
                                                                            <h4><Skeleton width={210} height={30} style={{ borderRadius: "50px" }} /></h4>
                                                                            <div className="mt-0">
                                                                                <a href="#listing-location" className="listing-address">
                                                                                    <p><Skeleton width={100} height={20} style={{ borderRadius: "50px" }} /></p>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                        <div className='similar-property slide mb-30'>
                                                            <Skeleton width={730} height={658} style={{ padding: "0px", border: '0px', boxShadow: 'none', borderRadius: "5px" }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='single  details mb-30'>
                                                    <Skeleton width={730} height={462} style={{ padding: "0px", border: '0px', boxShadow: 'none', borderRadius: "5px" }} />
                                                </div>
                                            </div>
                                            <aside className='col-lg-4 col-md-12 car'>
                                                <div className='single-widget'>
                                                    <div className='sidebar'>
                                                        <div className=' mt-33 '>
                                                            <Skeleton width={350} height={468.59} style={{ padding: "0px", border: '0px', boxShadow: 'none', borderRadius: "5px", marginTop: "11px" }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='main-search-field-2'>
                                                    <div className='mt-5'>
                                                        <Skeleton width={350} height={364.11} style={{ padding: "0px", border: '0px', boxShadow: 'none', borderRadius: "5px", marginTop: "11px" }} />
                                                    </div>
                                                    <div className='mt-5'>
                                                        <Skeleton width={350} height={364.11} style={{ padding: "0px", border: '0px', boxShadow: 'none', borderRadius: "5px", marginTop: "11px" }} />
                                                    </div>
                                                </div>
                                            </aside>
                                        </div>
                                    </div>
                                </section>
                            </>
                        }
                    </>
            }
        </>
    )
}

export default MailPropertyDetails;
