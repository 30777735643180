import React, { useEffect, useState, useRef } from "react";
// import {Link} from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import $, { data } from 'jquery'
import { fetchMenuRequest, toggleMenuRequest } from "./components/common/actions";
import { ClickAwayListener } from "@material-ui/core";
import { fetchPropertiesByFilterRequest, setSelectedOptionsRequest } from "./components/Landing/actions";
import { getQueryString } from "./helper";

const CustomNav = () => {
    const history = useHistory();
    const [toggleOptions, setToggleOptions] = useState(false);
    const [openSubPages, setOpenSubPages] = useState(false);
    /*** get Menu  */
    const dispatch = useDispatch();
    const navbarRef = useRef();
    const [getMenuData, setMenu] = useState([]);
    const [defaultPages, setDefaultPage] = useState([]);
    const [otherPages, setOtherPage] = useState([]);
    // const menu = useSelector(store => store.commonPageData.menu.length > 0 ? store.commonPageData.menu : store.commonPageData.menu)
    const menu = useSelector(store => store.commonPageData.menu)
    const toggleMenu = useSelector(store => store.commonPageData.toggleMenu)
    let allSelectedOption = useSelector(store => store.landingPageData.selectedOptions);

    const initialparams = {
        hot_property: '',
        property_type: '',
        property_for: '',
        specific_property: '',
        measurement: '',
        area_size: '',
        area: '',
        country: 101,
        state: '',
        city: '',
        page: 1,
        sort_by: 'latest',
        property_configuration: ''
    };

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        /** setProducts..! */
        if (typeof menu.data === 'object' && Object.keys(menu.data).length > 0) {
            setDefaultPage(menu.data.pages.default_pages);
            setOtherPage(menu.data.pages.other_pages);
        }
    }, [menu]);

    /*** get Menu - End */
    /** Custom function */
    function loadData() {
        dispatch(fetchMenuRequest(JSON.stringify([data])))
    }

    const handleHide = (e) => {
        if (toggleOptions) {
            dispatch(toggleMenuRequest());
            setToggleOptions(toggleMenu);
        }
    };

    useEffect(() => {
        setToggleOptions(toggleMenu);
        // dispatch(toggleMenuRequest());
    }, [toggleMenu]);

    const handleMenuClick = (event, filters = '') => {
        event.preventDefault();
        if (event.type === 'click') {
            let historypath = '/all/' + filters;
            let QueryString = "";
            if (filters != '' && filters == 'hot-property') {
                historypath = '/' + filters;
                allSelectedOption.hot_property = true;
            }
            if (filters != '' && filters == 'property') {
                allSelectedOption = initialparams;
            }
            dispatch(setSelectedOptionsRequest(allSelectedOption));
            if (typeof allSelectedOption === 'object' && Object.keys(allSelectedOption).length > 0) {
                QueryString = getQueryString(allSelectedOption);
                // dispatch the properties list by selected filter options..
                dispatch(fetchPropertiesByFilterRequest(JSON.stringify(allSelectedOption)))
            }
            history.push({
                pathname: historypath,
                search: `?${QueryString}`
            })
        }
    }

    return (<>

        {/* Mobile Menu Start */}
        <nav className={`head-tr mmenu-init mm-menu mm-offcanvas ${toggleOptions ? 'mm-opened' : ''}`} id="mm-6" aria-hidden="true" >
            <div className="mm-panels">
                <div className={`mm-panel mm-hasnavbar ${openSubPages ? 'mm-subopened mm-hidden' : 'mm-opened'}`} id="mm-7">
                    <div className="mm-navbar">
                        <a className="mm-title" aria-label="">Menu</a>
                    </div>
                    <ul className="mm-listview">
                        <li key="home" onClick={handleHide}><Link aria-label="" to={`/`}>Home</Link></li>
                        {process.env.REACT_APP_ENDPOINT != "thetatva" && (<><li onClick={handleHide}><Link aria-label="" to={`/all/property`} onClick={(event) => { handleMenuClick(event, 'property') }}>All Property</Link></li>
                        <li onClick={handleHide}><Link aria-label="" to={`/hot-property`} onClick={(event) => { handleMenuClick(event, 'hot-property') }}>Hot Property</Link></li>
                        <li onClick={handleHide}><Link area-label="" to={`/preleased-property`} >Pre-Leased Property</Link></li>
                        <li onClick={handleHide}><Link aria-label="" to={`/building/project`}>Building/Projects</Link></li></>)}
                        {process.env.REACT_APP_ENDPOINT != "thetatva" && otherPages && otherPages.length > 0 &&
                            <li>
                                <em className="mm-counter">{otherPages.length}</em>
                                <a aria-label="" className="mm-next mm-fullsubopen" href="#mm-8" onClick={(e) => { e.preventDefault(); setOpenSubPages(!openSubPages); }} aria-owns="mm-8" aria-haspopup="true">
                                    <span className="mm-sronly">Open submenu (Pages)</span>
                                </a>
                                <a href="#" aria-label="">Pages</a>
                            </li>
                        }
                        {defaultPages && defaultPages.length > 0 && defaultPages.map((item, index) => {
                            return (
                                <li key={index} onClick={handleHide}>
                                    <Link aria-label="" to={`/page/${item.slug}`}>{item.name}</Link>
                                </li>
                            );
                        })}
                    </ul>
                </div>

                <div className={`mm-panel  mm-hasnavbar ${openSubPages ? 'mm-opened' : 'mm-hidden'}`} id="mm-8" aria-hidden="true">
                    <div className="mm-navbar">
                        <a aria-label="" className="mm-btn mm-prev" href="#mm-7" onClick={(e) => { e.preventDefault(); setOpenSubPages(false) }} aria-owns="mm-7" aria-haspopup="true">
                            <span className="mm-sronly">Close submenu (Pages)</span>
                        </a>
                        <a aria-label="" className="mm-title" href="#mm-7" aria-hidden="true">Pages</a>
                    </div>
                    <ul className="mm-listview">
                        {process.env.REACT_APP_ENDPOINT != "thetatva" && otherPages && otherPages.length > 0 && otherPages.map((item, index) => {
                            return (
                                <li key={item.slug} onClick={handleHide}>
                                    <Link to={`/page/${item.slug}`} aria-label="">{item.name}</Link>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </nav>
        {/* Mobile Menu End */}
    </>);
}

export default CustomNav;